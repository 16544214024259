import React, { createContext, useRef, useEffect, useState } from "react";
import { io } from "socket.io-client";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const socketRef = useRef(null);
  const [connectionStatus, setConnectionStatus] = useState("Connecting...");

  useEffect(() => {
    socketRef.current = io("http://beta.bhuvaneshwaris.in/", {
      reconnectionAttempts: 5,
      reconnectionDelay: 5000,
      transports: ["websocket"],
      path: "/socket-live",
    });

    socketRef.current.on("connect", () => {
      setConnectionStatus("Connected");
      console.log("Socket connected:", socketRef.current.id);
      socketRef.current.emit("event", { TotalMemory: "3.68 GB" });
    });

    socketRef.current.on("disconnect", (reason) => {
      setConnectionStatus("Disconnected. Reconnecting...");
      console.log("Socket disconnected:", reason);
    });

    socketRef.current.on("connect_error", (error) => {
      setConnectionStatus("Connection error. Retrying...");
      console.error("Socket connection error:", error);
    });

    socketRef.current.on("back", (data) => {
      console.log("GET:::", data);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket: socketRef.current,
        connectionStatus,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
