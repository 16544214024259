import React, { useRef, useState, useContext } from "react";
import { useMeetingAppContext } from "../MeetingAppContextDef";
import { ParticipantView } from "./ParticipantView";
import { useTranscription, useMeeting } from "@videosdk.live/react-sdk";
import ai_copilot from '../ai_gifs/ai-copilot-1.gif';
import ai_listen from '../ai_gifs/ai-listen-1.gif';
import ai_loader from '../ai_gifs/ai-loader.gif';
import ai_speak from '../ai_gifs/ai-speak.gif';
import ai_logo from '../ai_gifs/ai.png';
import { io } from "socket.io-client";
import { SocketContext } from "../providers/SocketProvider";

const MemoizedParticipant = React.memo(
  ParticipantView,
  (prevProps, nextProps) => {
    return prevProps.participantId === nextProps.participantId;
  }
);

function ParticipantGrid({ participantIds, isPresenting }) {
  const { sideBarMode } = useMeetingAppContext();
  const { participants } = useMeeting();
  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;
  const [isListening, setIsListening] = useState(false);
  const [isAIAPICall, setIsAIAPICall] = useState(false);
  const [isAIPlay, setIsAIPlay] = useState(false);

  const finalTranscription = useRef(null);
  const { socket, connectionStatus } = useContext(SocketContext);

  const participantIdsWithAI = ["AI", ...participantIds];
  let participantsCount = participants.size;

  const onTranscriptionText = (data) => {
    let { participantId, participantName, text, timestamp, type } = data;
    let finalMessage = `${participantName}: ${text}`
    if (type === 'fullSentence' && (isListening || participantsCount === 1)) {
      finalTranscription.current = { participantName, text };
      callAI();
      socket.emit("event", { user_id: participantId, conversion: finalMessage, is_for_ai: true })
      setIsListening(false);
    }
    else {
      socket.emit("event", { user_id: participantId, conversion: finalMessage })
    }
  }

  const callAI = () => {
    setIsAIAPICall(true)
    setTimeout(() => {
      setIsAIAPICall(false)
      playAI()
    }, 3000);
  }

  const playAI = () => {
    setIsAIPlay(true)
    setTimeout(() => {
      setIsAIPlay(false)
    }, 3000);
  }


  const { startTranscription, stopTranscription } = useTranscription({
    onTranscriptionText,
  });

  // Function to handle the start of listening (manual trigger)
  const handleStartListening = () => {
    setIsListening(true);
    console.log("AI Assistant is listening...");
    // startListening();

    setTimeout(() => {
      callAI()
      setIsListening(false);
    }, 2000);

  };

  console.log("connectionStatus:  ", connectionStatus);

  const perRow =
    isMobile || isPresenting
      ? participantIdsWithAI.length < 4
        ? 1
        : participantIdsWithAI.length < 9
          ? 2
          : 3
      : participantIdsWithAI.length < 5
        ? 2
        : participantIdsWithAI.length < 7
          ? 3
          : participantIdsWithAI.length < 9
            ? 4
            : participantIdsWithAI.length < 10
              ? 3
              : participantIdsWithAI.length < 11
                ? 4
                : 4;

  return (
    <div
      className={`flex flex-col md:flex-row flex-grow m-3 items-center justify-center ${participantIdsWithAI.length < 2 && !sideBarMode && !isPresenting
          ? "md:px-16 md:py-2"
          : participantIdsWithAI.length < 3 && !sideBarMode && !isPresenting
            ? "md:px-16 md:py-8"
            : participantIdsWithAI.length < 4 && !sideBarMode && !isPresenting
              ? "md:px-16 md:py-4"
              : participantIdsWithAI.length > 4 && !sideBarMode && !isPresenting
                ? "md:px-14"
                : "md:px-0"
        }`}
    >
      {
        // finalTranscription?.participantName === 'AI' ?
        finalTranscription.current?.text ? 
        <div className="ai-message-display">
           {finalTranscription.current?.participantName} : {finalTranscription.current?.text}
        </div>
        :''
      }
      <div className="flex flex-col w-full h-full">
        {Array.from(
          { length: Math.ceil(participantIdsWithAI.length / perRow) },
          (_, i) => {
            return (
              <div
                key={`participant-${i}`}
                className={`flex flex-1 ${isPresenting
                    ? participantIdsWithAI.length === 1
                      ? "justify-start items-start"
                      : "items-center justify-center"
                    : "items-center justify-center"
                  }`}
              >
                {participantIdsWithAI
                  .slice(i * perRow, (i + 1) * perRow)
                  .map((participantId) => {
                    return (
                      <>
                        {participantId === "AI" ? (
                          <div
                            key={`participant_${participantId}`}
                            className={`ai-box-design flex flex-1 ${isPresenting
                                ? participantIdsWithAI.length === 1
                                  ? "md:h-48 md:w-44 xl:w-52 xl:h-48 "
                                  : participantIdsWithAI.length === 2
                                    ? "md:w-44 xl:w-56"
                                    : "md:w-44 xl:w-48"
                                : "w-full"
                              } items-center justify-center h-full ${participantIdsWithAI.length === 1
                                ? "md:max-w-7xl 2xl:max-w-[1480px] "
                                : "md:max-w-lg 2xl:max-w-2xl"
                              } overflow-clip overflow-hidden  p-1`}
                          //  style={{background:'#000000', height:'98%', borderRadius:10, position:'relative'}}
                          >
                            <div className="ai-avatar flex items-center justify-center" style={{ color: 'white', height: '50%', width: '60%' }}>
                              <span style={{ position: 'absolute', bottom: '4%', left: '4%' }}>
                                <div class="rounded-md flex items-center justify-center p-2" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', transition: '200ms linear', transform: 'scale(1)' }}>
                                  <p class="text-sm text-white ml-0.5" style={{ display: "flex" }}>
                                    <img src={ai_logo} alt="AI Symbol" style={{ marginRight: 5 }} />
                                    <span>AI Assistant</span>
                                  </p>
                                </div>
                              </span>
                              <span style={{ position: 'absolute', bottom: '18%', justifyContent: 'center', display: 'flex', flexDirection: 'column', textAlign: 'center', fontSize: 14, cursor: 'pointer', marginRight: '11%' }} >
                                {(isListening || isAIAPICall || isAIPlay) ? '' :
                                  <>
                                    {participantsCount !== 1 ? <div onClick={handleStartListening}>Click here to activate the AI</div> : ''}
                                    <div>Unmute your microphone before interacting with AI</div>
                                  </>}
                                {isListening ? <div>Listening . . .</div> : ''}
                                {isAIAPICall ? <div>Thinking . . .</div> : ''}
                                {isAIPlay ?
                                  <>
                                    <div>Speaking . . .</div>
                                    <div>Tap to stop the AI</div>
                                  </>
                                  : ''}


                              </span>
                              <div>
                                {(isListening || isAIAPICall || isAIPlay) ? '' : <img src={ai_copilot} alt="AI Copilot" style={{ maxWidth: '80%', height: '80%' }} />}
                                {isListening ? <img src={ai_listen} alt="AI Listen" style={{ maxWidth: '80%', height: '80%' }} /> : ''}
                                {isAIAPICall ? <img src={ai_loader} alt="AI Thinking" style={{ maxWidth: '80%', height: '80%' }} /> : ''}
                                {isAIPlay ? <img src={ai_speak} alt="AI Speak" style={{ maxWidth: '80%', height: '80%' }} /> : ''}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            key={`participant_${participantId}`}
                            className={`flex flex-1 ${isPresenting
                                ? participantIdsWithAI.length === 1
                                  ? "md:h-48 md:w-44 xl:w-52 xl:h-48 "
                                  : participantIdsWithAI.length === 2
                                    ? "md:w-44 xl:w-56"
                                    : "md:w-44 xl:w-48"
                                : "w-full"
                              } items-center justify-center h-full ${participantIdsWithAI.length === 1
                                ? "md:max-w-7xl 2xl:max-w-[1480px] "
                                : "md:max-w-lg 2xl:max-w-2xl"
                              } overflow-clip overflow-hidden  p-1`}
                          >
                            <MemoizedParticipant participantId={participantId} />
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}

export const MemoizedParticipantGrid = React.memo(
  ParticipantGrid,
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.participantIds) ===
      JSON.stringify(nextProps.participantIds) &&
      prevProps.isPresenting === nextProps.isPresenting
    );
  }
);
